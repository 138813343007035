import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Home from '../components/home'

const IndexPage = () => {
  const lang = 'en'

  return (
    <Layout lang={lang}>
      <SEO title="Home" />
      <Home lang={lang} />
    </Layout>
  )
}
export default IndexPage
